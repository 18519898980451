<template>
  <div>
    <b-modal
      @show="showModal"
      @hidden="hideModal"
      id="my-modal"
      ref="my-modal"
      size="lg"
      class="rounded-0"
      centered
      title="Slider Resim Ekle"
    >
      <b-card-text>
        <b-row>
          <b-col cols="12">
            <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              :options="dropzoneOptions"
              @vdropzone-complete="handlerComplated"
              @vdropzone-sending="sendingEvent"
            />
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" @click="onSubmit" :disabled="show">
            <feather-icon class="cursor-pointer" icon="SaveIcon" size="16" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            Kaydet
          </b-button>
          <b-button squared variant="danger" class="float-right mr-1" @click="toggleModal">
            <feather-icon class="cursor-pointer" icon="Trash2Icon" size="16" />
            iptal
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { diller } from '@core/mixins/ui/diller';
export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [diller],
  data() {
    return {
      show: false,
      dropzoneOptions: {
        paramName: 'slider',
        url:
          process.env.NODE_ENV == 'development'
            ? process.env.VUE_APP_DEV_BASEURL + '/web-yonetim/slide-ekle'
            : process.env.VUE_APP_PRO_BASEURL + '/web-yonetim/slide-ekle',
        method: 'POST',
        thumbnailWidth: 150,
        maxFilesize: 10,
        parallelUploads: 10,
        maxFile: 10,
        addRemoveLinks: true,
        dictDefaultMessage: 'Dosyaları yüklemek için buraya bırakın',
        uploadMultiple: false,
        autoProcessQueue: false,
        headers: { Authorization: 'Bearer: ' + localStorage.getItem('accessToken') },
      },
      imgs: [],
    };
  },
  created() {
    console.log(process.env.NODE_ENV);
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$nextTick(() => {
        this.$bvModal.hide('my-modal');
      });
      this.$emit('closeModal', true);
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn');
      this.$emit('closeModal', true);
    },
    async handlerComplated(res) {
      if (res.status === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Bilgi`,
            icon: 'CheckSquareIcon',
            variant: 'success',
            text: 'Ekleme Başarılı.',
          },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Hata`,
            icon: 'CheckSquareIcon',
            variant: 'success',
            text: 'Hata Oluştu.',
          },
        });
      }
      await this.$store.dispatch('slideListele');
      this.show = false;
      this.$refs.myVueDropzone.removeAllFiles();
      this.hideModal();
    },
    sendingEvent(files, xhr, formData) {
      let icerik = {};
      this.diller.forEach((dil) => {
        icerik[dil.lang] = { baslik: null, aciklama: null };
      });
      formData.append('icerik', JSON.stringify(icerik));
    },
    onSubmit() {
      this.show = true;
      this.$refs.myVueDropzone.processQueue();
    },
  },
  watch: {
    openModal: {
      handler(val) {
        if (val == true) {
          this.toggleModal();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
